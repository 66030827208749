@font-face {
  font-family: "Source Serif Pro";
  font-weight: 300;
  font-display: swap;
  src: local("myfont"), url("../SourceSerifPro-Regular.woff2") format("woff");
}

@font-face {
  font-family: "Source Serif Pro";
  font-weight: 500;
  font-display: swap;
  src: local("myfont"), url("../SourceSerifPro-Semibold.woff2") format("woff");
}

@font-face {
  font-family: "Source Serif Pro";
  font-weight: 900;
  font-display: swap;
  src: local("myfont"), url("../SourceSerifPro-Bold.woff2") format("woff");
}

@font-face {
  font-family: "Myriad Pro";
  font-weight: 200;
  font-display: swap;
  src: local("myfont"), url("../MyriadPro-Light.woff2") format("woff");
}

@font-face {
  font-family: "Myriad Pro";
  font-weight: 300;
  font-display: swap;
  src: local("myfont"), url("../MYRIADPRO-REGULAR.woff2") format("woff");
}

@font-face {
  font-family: "Myriad Pro";
  font-weight: 500;
  font-display: swap;
  src: local("myfont"), url("../MYRIADPRO-SEMIBOLD.woff2") format("woff");
}

@font-face {
  font-family: "Myriad Pro";
  font-weight: 900;
  font-display: swap;
  src: local("myfont"), url("../MYRIADPRO-BOLD.woff2") format("woff");
}

body {
  color: #002577;
  margin: 0 auto;
  padding: 0;
  font-family: Myriad Pro, Arial, sans-serif;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: unset;
}

h1 {
  font-family: Source Serif Pro, Arial, sans-serif;
  font-size: 48px;
  font-weight: 900;
}
