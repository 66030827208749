@font-face {
  font-family: Source Serif Pro;
  font-weight: 300;
  font-display: swap;
  src: local(myfont), url("SourceSerifPro-Regular.6033d711.woff2") format("woff");
}

@font-face {
  font-family: Source Serif Pro;
  font-weight: 500;
  font-display: swap;
  src: local(myfont), url("SourceSerifPro-Semibold.abe98dbe.woff2") format("woff");
}

@font-face {
  font-family: Source Serif Pro;
  font-weight: 900;
  font-display: swap;
  src: local(myfont), url("SourceSerifPro-Bold.6cc74412.woff2") format("woff");
}

@font-face {
  font-family: Myriad Pro;
  font-weight: 200;
  font-display: swap;
  src: local(myfont), url("MyriadPro-Light.0944f8f6.woff2") format("woff");
}

@font-face {
  font-family: Myriad Pro;
  font-weight: 300;
  font-display: swap;
  src: local(myfont), url("MYRIADPRO-REGULAR.eaaea00c.woff2") format("woff");
}

@font-face {
  font-family: Myriad Pro;
  font-weight: 500;
  font-display: swap;
  src: local(myfont), url("MYRIADPRO-SEMIBOLD.fe16da29.woff2") format("woff");
}

@font-face {
  font-family: Myriad Pro;
  font-weight: 900;
  font-display: swap;
  src: local(myfont), url("MYRIADPRO-BOLD.793aa160.woff2") format("woff");
}

body {
  color: #002577;
  margin: 0 auto;
  padding: 0;
  font-family: Myriad Pro, Arial, sans-serif;
  font-size: 16px;
}

a {
  color: unset;
  text-decoration: none;
}

h1 {
  font-family: Source Serif Pro, Arial, sans-serif;
  font-size: 48px;
  font-weight: 900;
}

.ety_Sa_headerContent {
  box-sizing: border-box;
  background-color: #fff;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 72px;
  padding: 20px 30px;
  display: flex;
}

@media (width >= 768px) {
  .ety_Sa_headerContent {
    padding: 30px 50px;
  }
}

.ety_Sa_optumContainer {
  justify-content: center;
  display: flex;
}

.ety_Sa_logoContainer {
  padding-left: 25px;
}

.ety_Sa_optumContainer img {
  width: 140px;
  height: 70px;
}

.jitYDq_bannerContent {
  padding: 0;
}

.jitYDq_bannerContent .jitYDq_bannerContainer {
  width: 100%;
  margin: 0;
}

@media (width >= 768px) {
  .jitYDq_bannerContent .jitYDq_bannerContainer {
    flex-direction: row-reverse;
    margin-bottom: 80px;
  }
}

.jitYDq_bannerImage {
  height: 250px;
}

@media (width >= 768px) {
  .jitYDq_bannerImage {
    height: unset;
    border-radius: 0 0 0 80px;
  }
}

.jitYDq_bannerText {
  padding-left: 20px;
  padding-right: 20px;
}

@media (width >= 768px) {
  .jitYDq_bannerText {
    padding: 30px 50px 0;
  }
}

.jitYDq_bannerText h1 {
  margin-bottom: 12px;
  line-height: 54px;
}

.jitYDq_bannerText .jitYDq_bannerSubTitle {
  color: #000;
  margin-top: 0;
  font-size: 18px;
  font-weight: 200;
}

@media (width >= 992px) {
  .jitYDq_bannerText .jitYDq_bannerSubTitle {
    font-size: 20px;
  }
}

.jitYDq_bannerText .jitYDq_bannerContact {
  color: #cc2e38;
  font-weight: 200;
}

@media (width >= 768px) {
  .jitYDq_bannerText .jitYDq_bannerContact {
    margin-bottom: 0;
  }
}

@media (width >= 992px) {
  .jitYDq_bannerText .jitYDq_bannerContact {
    font-size: 32px;
  }
}

.iObf1G_servicesContent {
  color: #fff;
  text-align: center;
  background-color: #022c6b;
  padding: 40px 20px;
}

.iObf1G_servicesContent p {
  font-size: 24px;
  font-weight: 300;
}

.iObf1G_title {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-size: 36px;
}

.iObf1G_servicesItemWrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.iObf1G_servicesItemContainer {
  max-width: 1100px;
  margin: 0 auto;
}

.iObf1G_servicesItem {
  color: #022c6b;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  margin: 15px;
  display: flex;
}

.iObf1G_servicesItem h2 {
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: 500;
}

.iObf1G_servicesItem p {
  margin-top: 12px;
  font-size: 18px;
}

.iObf1G_item {
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
}

.iObf1G_star {
  padding-left: 25px;
  padding-right: 25px;
}

.iObf1G_virtualCare {
  margin-top: 16px;
}

.__oPnG_button {
  cursor: pointer;
  color: #faf8f2;
  background-color: #002577;
  border: 0;
  border-radius: 20px;
  padding: 10px 35px;
  font-family: Myriad Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

@media (width >= 992px) {
  .__oPnG_button {
    font-size: 16px;
  }
}

.InLx6a_supportContent {
  background-color: #f5f5f5;
  padding: 60px 20px;
}

.InLx6a_supportTitle {
  text-align: center;
}

.InLx6a_supportTitleText {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 36px;
  font-weight: bold;
  line-height: 40px;
}

.InLx6a_supportItem {
  padding-top: 50px;
}

.InLx6a_supportImage {
  height: 315px;
}

.InLx6a_supportItemWrapper h2 {
  color: #cc2e38;
  margin-bottom: 12px;
  font-size: 28px;
  font-weight: 900;
}

@media (width >= 992px) {
  .InLx6a_supportItemWrapper h2 {
    margin-right: 30px;
  }
}

.InLx6a_supportItemWrapper p {
  color: #000;
  margin-top: 0;
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
}

@media (width >= 992px) {
  .InLx6a_supportItemWrapper p {
    padding-right: 55px;
  }
}

.InLx6a_supportButton {
  margin-top: 10px;
  margin-right: 10px;
}

.InLx6a_supportWrapper {
  max-width: 1100px;
  margin: auto;
}

@media (width >= 768px) {
  .InLx6a_readMore1 {
    margin-top: 23px;
  }
}

.thDDtG_supportTeensContent {
  color: #fff;
  background-color: #022c6b;
  padding-bottom: 60px;
}

.thDDtG_supportTeensContent .thDDtG_selfCareWrapper {
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.thDDtG_supportTeensContent .thDDtG_supportWrapper {
  margin: 0;
}

.thDDtG_supportTeensContent .thDDtG_supportImage {
  padding: 0;
}

.thDDtG_selfCareImageWrapper {
  height: 250px;
}

@media (width >= 768px) {
  .thDDtG_selfCareImageWrapper {
    border-radius: 0 0 80px;
    height: 450px;
  }
}

.thDDtG_useCode {
  font-size: 22px;
}

.thDDtG_selfCareText {
  text-align: center;
  max-width: 450px;
  margin: auto;
}

@media (width >= 768px) {
  .thDDtG_selfCareText {
    text-align: left;
    padding-left: 30px;
  }
}

@media (width >= 992px) {
  .thDDtG_selfCareText {
    margin: 0;
  }
}

.thDDtG_selfCareText h1 {
  font-size: 36px;
}

@media (width >= 992px) {
  .thDDtG_selfCareText h1 {
    padding-right: 100px;
  }
}

.thDDtG_selfCareText h2 {
  font-weight: 500;
}

.thDDtG_selfCareText h3 {
  font-weight: 300;
}

.thDDtG_selfCareText p {
  font-size: 18px;
  font-weight: 200;
}

.thDDtG_storeContainer {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (width >= 768px) {
  .thDDtG_storeContainer {
    justify-content: flex-start;
  }
}

.thDDtG_storeContainer img {
  padding: 0 5px;
}

@media (width >= 768px) {
  .thDDtG_storeContainer img {
    padding-left: 0;
  }
}

.thDDtG_boxWrapper {
  max-width: 1000px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.thDDtG_boxItem {
  text-align: center;
  background-color: #fff;
  max-width: 250px;
  margin: 8px auto;
  padding: 35px 56px 20px;
  box-shadow: 0 1px 4px #00000029;
}

.thDDtG_boxItem h3 {
  color: #022c6b;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 22px;
}

@media (width >= 992px) {
  .thDDtG_box1 {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.thDDtG_link {
  color: #1c70cf;
  font-size: 18px;
  font-weight: 500;
}

.aet_Ra_helpContent {
  background-color: #f3f3f4;
  padding: 40px;
}

@media (width >= 992px) {
  .aet_Ra_helpContent {
    padding: 20px 40px;
  }
}

.aet_Ra_helpWrapper {
  max-width: 1100px;
  margin: auto;
}

@media (width >= 992px) {
  .aet_Ra_helpWrapper {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.aet_Ra_helpTexts h1 {
  margin-bottom: 0;
  font-size: 36px;
}

.aet_Ra_helpTexts p {
  color: #000;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 200;
}

._3p0KfG_footerContent {
  background-color: #fff;
  padding: 60px 40px;
  font-weight: 300;
}

._3p0KfG_footerWrapper {
  color: #4b4d4f;
  max-width: 1100px;
  margin: auto;
  font-size: 14px;
}

._3p0KfG_footerWrapper p {
  margin: 20px 0;
}

._3p0KfG_footerLogoContainer {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

._3p0KfG_optumContainer {
  justify-content: center;
  padding-right: 25px;
  display: flex;
}

._3p0KfG_optumContainer img {
  width: 100px;
  height: 30px;
}

@media (width >= 768px) {
  ._3p0KfG_optumContainer img {
    width: 137px;
    height: 40px;
  }
}

._3p0KfG_logoContainer img {
  width: 75px;
  height: 30px;
}

@media (width >= 768px) {
  ._3p0KfG_logoContainer img {
    width: 93px;
    height: 40px;
  }
}

.QoO7DW_appContainer {
  cursor: default;
  background-color: #fff;
  justify-content: center;
  display: flex;
}

.QoO7DW_app {
  width: 100%;
  max-width: 1440px;
  position: relative;
}
/*# sourceMappingURL=index.c38ec410.css.map */
